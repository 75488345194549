import * as React from "react"
import { SEO } from "../components/seo"
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby";

import heroBanner from '../images/platform-hero.jpg';

const Platform = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="
      h-[600px]
      lg:h-[750px]
      bg-primary
      bg-no-repeat
      bg-top
      bg-cover
      relative"
      style={{ backgroundImage: `url(${heroBanner})`}}>
        <div className="absolute w-full lg:h-3/5 inset-X-0 bottom-0 pt-28 pb-20 lg:pt-10 lg:pb-20 z-10 bg-gradient-to-t from-primary px-4 lg:px-20 lg:flex lg:flex-col lg:justify-end text-center">
          <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-5xl lg:text-7xl text-white mb-2">How We <span className="text-secondary font-bold">Win</span></h1>
          <p className="animate__animated animate__fadeInUp animate__slower text-xl font-light text-white drop-shadow-sm leading-relaxed">Kim Nguyen-Penaloza represents the next generation of new American leadership.</p>
        </div>
      </section>
      <section className="py-28 px-10 lg:px-36">

        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6">

          <p>A millenial, pro-choice, bi-racial daughter of an immigrant and a refugee, Kim Nguyen-Penaloza is the face of political change in California and Washington.</p>

          <p><strong>CA-45 is one of the best pickup opportunities for Democrats in 2024!</strong></p>

          <p>A Biden +6 district, the demographics and voter registration give Democrats an edge.</p>

          <StaticImage src="../images/CA45-map.png" alt="Democrats 37.8%; Republicans 32.1%; NPP 24.4%;" placeholder="blurred" layout="fullWidth" className="mb-5" />

          <p>Asian American Pacific Islander (AAPI) and Latino voters are a majority of the district at 51.6% -- with Vietnamese voters making up the largest block of AAPI voters at 58.4%.</p>

          <p>CA-45 is anchored around Orange County’s Little Saigon, home to the largest population of Vietnamese in the world outside of Vietnam, and includes many neighborhoods that feature Los Angeles and Orange County’s growing Asian-American population.</p>

          <p>If elected, Kim would be the first person of Vietnamese descent ever elected to congress from California, and only the third in US history. She would also be the first Asian-Latina woman in Congress and one of a growing group of millennials seeking to make change on our most crucial issues.</p>

          <p className="font-bold text-2xl mb-4 text-center text-primary">Help us make history and flip CA-45 blue! </p>

          <p className="text-center">
            <a href="https://secure.actblue.com/donate/votekimnguyen"
              className="
              mt-10
              font-bold
              text-black
              bg-secondary
              text-lg
              rounded-full
              py-4
              px-8
              leading-none
              text-center
              transition-all
              duration-500
              hover:bg-secondary-dark
              ">
                Donate Now
                <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
                  <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
                </svg>
              </a>
          </p>

        </div>
      </section>
    </Layout>
  )
}

export default Platform

export const Head = ({location}) => <SEO title="How We Win" pathname={location.pathname} />
